<template>
  <div id="Role">
    <!-- 组织架构 角色 -->
    <!-- 抽屉模式 -->
    <el-drawer title="我是标题"
               :visible.sync="drawer"
               :direction="direction"
               :before-close="handleClose"
               size="40%"
               wrapperClosable
               append-to-body>
      <el-tree :data="jsonRolePowerList"
               show-checkbox
               node-key="id"
               default-expand-all
               :expand-on-click-node="false"
               :default-checked-keys="[5]">
        <span class="custom-tree-node"
              slot-scope="{ node, jsonRolePowerList }">
          <span>{{ node.label }}</span>
          <span>{{ jsonRolePowerList }}</span>
        </span>
      </el-tree>
    </el-drawer>

    <!-- Dialog 对话框 S-->
    <el-dialog :title="openType === 'add' ? '新增角色' : '编辑角色'"
               :visible.sync="isShowDialogVisible">
      <CommonForm :form="openForm"
                  :formLabel="openFormLabel"
                  inline></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="cancelPowerForm()">取 消</el-button>
        <el-button type="primary"
                   @click="subPowerForm(openType, openForm)">确 定</el-button>
      </div>
    </el-dialog>
    <!-- Dialog 对话框  E-->

    <CommonManageMain>
      <template v-slot:header>
        <div>
          <el-button type="primary"
                     size="small"
                     icon="el-icon-circle-plus-outline"
                     @click="clickAddRole()">增加
          </el-button>
          <el-button type="danger"
                     size="small"
                     icon="el-icon-delete">删除</el-button>
        </div>

        <CommonForm :form="searchForm"
                    :formLabel="formLabel"
                    inline>
          <el-button type="primary">搜索</el-button>
        </CommonForm>
      </template>

      <!-- 表格内容框 -->
      <template v-slot:content>
        <CommonTable :tableData="tableData"
                     :tableLabel="tableLabel"
                     :tableConfig="tableConfig">
          <el-table-column label="操作"
                           width="180">
            <template slot-scope="scope">
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="编辑"
                          placement="top">
                <el-button type="primary"
                           size="mini"
                           icon="el-icon-edit"
                           @click="clickEditUser(scope.row)"></el-button>
              </el-tooltip>
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="删除"
                          placement="top">
                <el-button type="danger"
                           size="mini"
                           icon="el-icon-delete"></el-button>
              </el-tooltip>

              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="关联权限"
                          placement="top">
                <el-button type="warning"
                           size="mini"
                           icon="el-icon-setting"
                           @click="clickEditRole(scope.row)"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </CommonTable>
      </template>
    </CommonManageMain>
  </div>
</template>

<script>
import { getRolePower, metRoleList } from '/src/api/organize.js'

import CommonForm from 'components/CommonForm.vue'
import CommonTable from 'components/CommonTable.vue'
import CommonManageMain from 'components/CommonManageMain.vue'

export default {
  components: {
    CommonForm,
    CommonTable,
    CommonManageMain
  },
  data () {
    return {
      // 抽屉栏目
      drawer: false,
      direction: 'rtl',

      // 对话框 Dialog 基础数据
      openType: 'add',
      isShowDialogVisible: false,

      // form表单 基础数据
      openForm: {
        name: '',
        description: '',
        status: true
      },
      openFormLabel: [
        {
          model: 'name',
          label: '角色名称'
        },
        {
          model: 'description',
          label: '角色描述'
        },
        {
          model: 'status',
          label: '状态',
          type: 'switch'
        }
      ],

      // form表单 搜索数据
      searchForm: {
        keyword: ''
      },
      formLabel: [
        {
          model: 'keyword',
          label: '角色',
          labelOFF: true,
          options: []
        }
      ],

      // 表格数据
      tableData: [],
      tableLabel: [
        {
          prop: 'role',
          label: '角色'
        },
        {
          prop: 'note',
          label: '备注'
        },
        {
          prop: 'state',
          label: '状态',
          type: 'state'
        }
      ],
      tableConfig: {
        isShowLoading: false,
        loading: false,
        alterState: 'state'
      },

      // 全部数据
      allData: [],
      // 角色的权限列表
      RolePowerList: [],
      jsonRolePowerList: []
    }
  },
  mounted () {
    this.getRoleList()
  },
  watch: {
    allData: function () {
      this.roleTableData()
    }
  },
  methods: {
    // 获取角色list数据
    getRoleList () {
      metRoleList().then(res => {
        // name: "CEO"
        // description: "首席执行官"
        // status: 1
        this.allData = res.data.data.list
      })
    },
    // 角色的权限 列表 get请求  ======再优化========
    getRolePowerList (id) {
      getRolePower(id).then(res => {
        // 数据格式
        // [
        //   {
        //     id: 1,
        //     label: '一级 name',
        //     children: [
        //       {
        //         id: 2,
        //         label: '二级 name',
        //         children
        //       }
        //     ]
        //   }
        // ]
        // this.RolePowerList
        this.RolePowerList = []
        const datas = res.data.data
        datas.forEach(item => {
          const cArr = []
          item.child.forEach(cItem => {
            const pArr = []
            cItem.permission.forEach(pItem => {
              pArr.push({
                id: pItem.id,
                label: pItem.name,
                group_id: pItem.permission_group_id,
                hold: pItem.hold
              })
            })

            cArr.push({
              id: cItem.id,
              label: cItem.name,
              parent_id: cItem.parent_id,
              children: pArr
            })
          })

          this.RolePowerList.push({
            id: item.id,
            label: item.name,
            parent_id: item.parent_id,
            children: cArr
          })
        })

        this.jsonRolePowerList = JSON.parse(JSON.stringify(this.RolePowerList))
        console.log('jsonRolePowerList', this.jsonRolePowerList)
      })
    },

    // 添加角色--发送到后端
    addMethodRoleList (method, args) {
      metRoleList(method, args).then(res => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.getRoleList()
        }
      })
    },

    // 渲染table 数据
    roleTableData () {
      this.tableData = []
      const datas = this.allData
      datas.forEach(item => {
        this.tableData.push({
          id: item.id,
          role: item.name,
          note: item.description,
          state: Boolean(item.status)
        })
      })
      console.log('this.tableData', this.tableData)
    },

    // 取消 dialog
    cancelPowerForm () {
      this.isShowDialogVisible = false
    },

    // 确定提交 dialog
    subPowerForm (type, val) {
      let subVal = {}
      // JSON.parse(JSON.stringify(obj)) 利用深拷贝 不影响 原数据
      subVal = Object.assign({}, val)
      subVal.status = Number(subVal.status)

      type == 'add'
        ? this.addMethodRoleList('POST', subVal)
        : this.addMethodRoleList('PUT', subVal)
      this.isShowDialogVisible = false
    },

    // 新增角色
    clickAddRole () {
      this.openForm.name = ''
      this.openForm.description = ''
      this.isShowDialogVisible = true
    },

    // 编辑角色
    clickEditUser (row) {
      //  openForm: {
      //         name: '',
      //         description: '',
      //         status: true,
      //       },
      //       openFormLabel: [
      //         {
      //           model: 'name',
      //           label: '角色名称',
      //         },

      const Nrow = JSON.parse(JSON.stringify(row))
      this.openForm.id = Nrow.id
      this.openForm.name = Nrow.role
      this.openForm.description = Nrow.note
      this.openForm.status = Nrow.state

      const status = Number(Nrow.state)

      this.isShowDialogVisible = true
      this.openType = 'edit'
    },

    // 角色修改权限
    clickEditRole (val) {
      this.drawer = true
      this.getRolePowerList({ role_id: val.id })
    },

    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => { })
    }
  }
}
</script>

<style lang="scss" scoped>
#Role {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 100%;
}
.el-tree {
  padding: 20px;

  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }
}
</style>
