import { request } from './request'
import qs from 'qs'

// 角色的权限 rbac/role-permission
export function getRolePower (params) {
  return request({
    url: '/supplier/rbac/role-permission',
    method: 'GET',
    params
  })
}

// 角色 POST PUT DELETE
export function metRoleList (method, datas) {
  return request({
    url: '/supplier/rbac/role',
    method,
    data: qs.stringify(datas)
  })
}

// 权限组
export function metMenuList (method, datas) {
  return request({
    url: '/supplier/rbac/permission-group',
    method,
    data: qs.stringify(datas)
  })
}

// 获取 权限列表
export function getPowerData (params) {
  return request({
    url: '/supplier/rbac/permission',
    method: 'GET',
    params
  })
}

// 权限操作（增加，修改）
export function methPowerData (method, datas) {
  return request({
    url: '/supplier/rbac/permission',
    method:'POST',
    data: qs.stringify(datas)
  })
}

// 用户列表
export function getUserList (params) {
  return request({
    url: '/supplier/admin-user',
    method: 'GET',
    params
  })
}

// 用户 添加 修改
export function methUserData (method, datas) {
  return request({
    url: '/supplier/admin-user',
    method,
    data: qs.stringify(datas)
  })
}
// 用户 状态更新
export function postUserStateData (datas) {
  return request({
    url: '/supplier/admin-user/change-status',
    method: 'POST',
    data: qs.stringify(datas)
  })
}
// 用户 关联的 角色
export function getUserOfRoleData (params) {
  return request({
    url: '/supplier/admin-user/role-permission',
    method: 'GET',
    params
  })
}
// 用户 授权 角色
export function postUserAuthorizeRoleData (datas) {
  return request({
    url: '/supplier/admin-user/role-permission',
    method: 'POST',
    data: qs.stringify(datas)
  })
}
